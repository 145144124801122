/*
 * @Author: YuJun
 * @Date: 2022-12-13 10:52:45
 * @LastEditTime: 2022-12-30 09:37:37
 * @LastEditors: YuJun
 * @Description: 
 * @copyright kawk
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import '@/assets/style/base.scss';

import 'element-plus/dist/index.css'

createApp(App).use(store).use(router).use(ElementPlus,{locale: zhCn,}).mount('#app')
