<!--
 * @Author: YuJun
 * @Date: 2022-12-13 10:52:45
 * @LastEditTime: 2022-12-13 10:55:08
 * @LastEditors: YuJun
 * @Description: 
 * @copyright kawk
-->
<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  setup() {
   
  },
};
</script>
<style lang="scss"></style>
