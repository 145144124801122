/*
 * @Author: YuJun
 * @Date: 2021-12-14 10:57:52
 * @LastEditTime: 2022-11-03 17:45:56
 * @LastEditors: YuJun
 * @Description: 本地storage事件封装
 * @copyright kawk
 */
const storageEffect = () => {
  const namespace = 'kawk-mg';
  
  /**
   * @description: 获取storage
   * @return {*}
   */  
  const getStorage = () => {
    return JSON.parse(localStorage.getItem(namespace) || "{}")
   };
  const storage = getStorage();

  /**
   * @description: 设置storage项
   * @param {*} key
   * @param {*} val
   * @return {*}
   */  
  const setItem= (key, val) => {
    storage[key] = val;
    localStorage.setItem(namespace, JSON.stringify(storage))
   };

   /**
    * @description: 获取storage项
    * @param {*} key
    * @return {*}
    */   
   const getItem = (key) => {
    return getStorage()[key]
   };

   /**
    * @description: 清除storage项
    * @param {*} key
    * @return {*}
    */   
   const clearItem = (key) => {
    delete storage[key];
    localStorage.setItem(namespace, JSON.stringify(storage))
   };

   /**
    * @description: 清除storage
    * @return {*}
    */   
   const clearAll = () => {
    localStorage.clear()
   }

   /**
    * @description: 获取session
    * @return {*}
    */
   const getSession = () => {
    return JSON.parse(sessionStorage.getItem(namespace) || "{}")
   };

 const session = getSession();   

 /**
  * @description: 设置session
  * @param {*} key
  * @param {*} val
  * @return {*}
  */ 
 const sessionSet = (key, val) => {
   session[key] = val;
   sessionStorage.setItem(namespace, JSON.stringify(session))
 }

 /**
  * @description: 获取session项
  * @param {*} key
  * @return {*}
  */ 
 const sessionGet = (key) => {
  return getSession()[key]
}

/**
 * @description: 清除session项
 * @param {*} key
 * @return {*}
 */
const sessionRemove = (key) => {
  delete session[key];
  sessionStorage.setItem(namespace, JSON.stringify(session))
}

/**
 * @description: 清除session
 * @return {*}
 */
const sessionClear = () => {
  sessionStorage.removeItem(namespace)
}

 return { setItem, getItem, clearItem, clearAll, sessionSet, sessionGet, sessionRemove, sessionClear }
}
export default storageEffect