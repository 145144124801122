/*
 * @Author: YuJun
 * @Date: 2022-11-03 19:59:40
 * @LastEditTime: 2022-11-03 20:23:30
 * @LastEditors: YuJun
 * @Description: vuex
 * @copyright kawk
 */
import { createStore } from "vuex";
import storageEffect from "../utils/stroage";

const { getItem, setItem, clearItem} = storageEffect();

export default createStore({
  /**
   * @description: vuex数据
   * @return {*}
   */  
  state: {
    userName:'',
    token:getItem("token") || {},
    actionList:getItem("actionList") || [],
    axiosCancelArr:[],
  },
  /**
   * @description: vuex事件
   * @return {*}
   */  
  mutations: {
    /**
     * @description: 本地保存用户名
     * @param {*} state
     * @param {*} payload
     * @return {*}
     */    
    setUser(state, payload){
      const { data } = payload;
      state.userName = data;   
    },

    /**
     * @description: 本地保存token
     * @param {*} state
     * @param {*} payload
     * @return {*}
     */    
    setToken(state, payload){
      const { data } = payload;
      state.token = data;
      setItem('token', data)    
    },

    /**
     * @description: 清除token
     * @return {*}
     */    
    clearToken(){
      clearItem('token')
      clearItem('actionList')
    },

    /**
     * @description: 本地保存权限标识
     * @param {*} state
     * @param {*} payload
     * @return {*}
     */    
    setAction(state,payload){
      state.actionList = payload
      setItem('actionList', payload)    
    },

    /**
     * @description: 跳转到取消请求前的route
     * @param {*} state
     * @param {*} payload
     * @return {*}
     */    
    pushCancel(state,payload){
      state.axiosCancelArr.push(payload.cancelToken);
    },

    /**
     * @description: 取消请求
     * @param {*} state
     * @return {*}
     */    
    clearCancel(state){
      state.axiosCancelArr.forEach(e=>{
        e && e()
      });
      state.axiosCancelArr = []
    },

  },
  actions: {},
  modules: {},
});
