/*
 * @Author: YuJun
 * @Date: 2022-12-13 10:52:45
 * @LastEditTime: 2022-12-13 16:34:51
 * @LastEditors: YuJun
 * @Description: 
 * @copyright kawk
 */
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
    meta: {
     title:'首页'
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: '登录'
  },
    component: () => import("../views/Login"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  if (to.name) {
     if (router.hasRoute(to.name)) {
         document.title = to.meta.title;
         next()
     } else {
         next('/404')
     }  
 } else {
     await loadRoute();   
     let curRoute = router.getRoutes().filter(item => item.path == to.path);
     if (curRoute?.length) {
        document.title = curRoute[0].meta.title; 
        next({ ...to, replace: true });
        
     } else {
      next('/404') 
     }
 }

})


export default router
